import React, {useEffect, useRef, useState} from 'react';
import {Select, Text} from '@chakra-ui/react';
import {useIsMount} from "./Scenario";

function ConditionComponent({ onChange, id, value: condition, vars, mt, ...restProps }) {
    onChange = onChange || (() => {});
    const initialKey = (condition && condition['=='] && condition['=='][0] && condition['=='][0].var) || null;
    const initialValue = (condition && condition['=='] && condition['=='][1]) || null;

    const isMount = useIsMount();
    const [type, setType] = useState(initialKey ? (initialKey === 'path' ? 'path' : 'var') : '');
    const [kv, setKv] = useState({key: initialKey, value: initialValue});
    const pathElement = useRef();
    const varKeyElement = useRef();
    const varValueElement = useRef();

    useEffect(() => {
        if (isMount) return;

        if (kv.key === null || type === null) {
            onChange(null);
            return;
        }

        if (type === 'path' && kv.value === null) {
            pathElement.current && handleChangingValue(pathElement.current?.value);
        }
        if (type === 'var' && kv.value === null) {
            varValueElement.current && handleChangingValue(varValueElement.current?.value);
        }

        if (kv.value !== null && kv.key !== null) {
            onChange({ '==': [{var: kv.key}, kv.value] });
        }
    }, [kv]);

    useEffect(() => {
        if (isMount) return;

        if (type === null) {
            handleChangingKey(null);
        }
        if (type === 'path') {
            handleChangingKey('path');
        }
        if (type === 'var') {
            varKeyElement.current && handleChangingKey(varKeyElement.current?.value);
        }
    }, [type]);

    const handleChangingType = (v) => {
        setType(v || null);
    }

    const handleChangingKey = (v) => {
        setKv({key: v, value: null});
    }

    const handleChangingValue = (v) => {
        if (vars.find(v => v.name === kv.key)?.type === 'bool') {
            v = 'true' === v;
        }

        setKv({...kv, value: v});
    }

    return (
        <>
            <Select mt={mt}
                id={id + 'c'}
                opacity={kv.key ? 1 : 0.5}
                defaultValue={type}
                onChange={(e) => handleChangingType(e.target.value)}
                {...restProps}
                focusBorderColor={'none'}
                w={0}
                minW={'200px'}
                display={'inline-block'}
            >
                <option key={id + 'none'} value="">Brak warunków</option>
                <option key={id + 'path'} value="path">Jeśli ścieżka</option>
                {vars.filter(v => ['str', 'bool'].includes(v.type)).length ? <option key={id + 'var'} value="var">Jeśli zmienna</option> : ''}
            </Select>

            {type === 'path' && (
                <>
                    <Text mx={2} display={'inline-block'}>=</Text>

                    <Select w={0} minW={'200px'} display={'inline-block'}
                        onChange={e => handleChangingValue(e.target.value)}
                        defaultValue={kv.value}
                        ref={pathElement}
                        id={id + 'cp'}
                    >
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                        <option value="e">E</option>
                        <option value="f">F</option>
                        <option value="g">G</option>
                    </Select>
                </>
            )}

            {type === 'var' && (
                <>
                    <Select w={0} minW={'200px'} ml={2} display={'inline-block'}
                        onChange={e => handleChangingKey(e.target.value)}
                        defaultValue={kv.key}
                        ref={varKeyElement}
                        id={id + 'cvk'}
                    >
                        {vars.filter(v => ['str', 'bool'].includes(v.type)).map(v => (
                            <option key={id + v.name} value={v.name}>{v.name}</option>
                        ))}
                    </Select>

                    <Text mx={2} display={'inline-block'}>=</Text>

                    {(vars.find(v => v.name === kv.key)?.type === 'bool') && (
                        <Select w={0} minW={'200px'} display={'inline-block'}
                            onChange={e => handleChangingValue(e.target.value)}
                            defaultValue={kv.value}
                            ref={varValueElement}
                            id={id + 'cvv'}
                        >
                            <option key={id + 'true'} value="true">tak</option>
                            <option key={id + 'false'} value="false">nie</option>
                        </Select>
                    )}
                </>
            )}
        </>
    );
}

export default ConditionComponent;
